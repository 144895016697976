import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { AboutRuralAttractivenessExplorerComponent } from './pages/about-rural-attractiveness-explorer/about-rural-attractiveness-explorer.component';
import { ComparingRuralAttractivenessComponent } from './pages/comparing-rural-attractiveness/comparing-rural-attractiveness.component';
import { ExploringDynamicsComponent } from './pages/exploring-dynamics/exploring-dynamics.component';
import { ExploringImpactComponent } from './pages/exploring-impact/exploring-impact.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PolicyOptionsExplorerComponent } from './pages/policy-options-explorer/policy-options-explorer.component';
import { RegionalForesightComponent } from './pages/regional-foresight/regional-foresight.component';
import { RuralAttractivenessExplorerComponent } from './pages/rural-attractiveness-explorer/rural-attractiveness-explorer.component';
import { SystemDynamicsModellingComponent } from './pages/system-dynamics-modelling/system-dynamics-modelling.component';
import { TextMiningComponent } from './pages/text-mining/text-mining.component';

const routes: Routes = [
    { path: 'regional-foresight', component: RegionalForesightComponent },
    { path: 'text-mining', component: TextMiningComponent },
    { path: 'system-dynamics', component: SystemDynamicsModellingComponent },
    { path: 'policy-options-explorer', component: PolicyOptionsExplorerComponent },
    {
        path: 'rural-attractiveness-explorer',
        component: RuralAttractivenessExplorerComponent,
        children: [
            {
                path:'',
                redirectTo: 'about',
                pathMatch: 'full' 
            },            
            { path: 'about', component: AboutRuralAttractivenessExplorerComponent },
            { path: 'comparing-rural-attractiveness', component: ComparingRuralAttractivenessComponent },
            { path: 'exploring-dynamics', component: ExploringDynamicsComponent },
            { path: 'exploring-impact', component: ExploringImpactComponent },
        ]
    },
    { path: '**', component: LandingPageComponent } // Default route

]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }