import {Component, OnInit} from '@angular/core';

import domain2scenario from '../../../assets/data/domain-scenario.json';
import {ScenarioFactorYearGraphService} from './scenario-factor-year-graph.service';
import {SdmDihService} from '../../sdm-dih.service';

@Component({
  selector: 'scenario-factor-year-graph',
  templateUrl: './scenario-factor-year-graph.component.html',
  styleUrls: ['./scenario-factor-year-graph.component.scss'],
})
export class ScenarioFactorYearGraphComponent implements OnInit {
  filteredScenarios = [];
  selectedRegion = 'Apulia';
  selectedDomain = 'INFR';

  constructor(
    public sdmDihService: SdmDihService,
    public yearGraphService: ScenarioFactorYearGraphService
  ) {}

  ngOnInit() {
    this.sdmDihService.dataLoads.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
      this.filterScenarios();
    });
    this.yearGraphService.graphRefreshes.subscribe(() => {
      this.filterScenarios();
    });
  }

  filterScenarios(): void {
    this.filteredScenarios = this.sdmDihService.scenarios.filter((scenario) => {
      return domain2scenario[this.selectedDomain].includes(scenario);
    });
  }

  baselineFilter = (item): boolean => {
    return item !== 'baseline';
  };

  animateGraphs() {
    const MILLISECONDS_TO_ANIMATE = 100 as const;
    let i = 1;
    for (const year of this.sdmDihService.years) {
      setTimeout(() => {
        this.yearGraphService.selectedYear = year;
        this.yearGraphService.redrawGraphs();
      }, MILLISECONDS_TO_ANIMATE * i);
      i++;
    }
  }

  nextYear() {
    const selectedYearIndex = this.sdmDihService.years.findIndex(
      (val) => val == this.yearGraphService.selectedYear
    );
    this.yearGraphService.selectedYear =
      this.sdmDihService.years[selectedYearIndex + 1] ??
      this.yearGraphService.selectedYear;
    this.yearGraphService.redrawGraphs();
  }

  prevYear() {
    const selectedYearIndex = this.sdmDihService.years.findIndex(
      (val) => val == this.yearGraphService.selectedYear
    );
    this.yearGraphService.selectedYear =
      this.sdmDihService.years[selectedYearIndex - 1] ??
      this.yearGraphService.selectedYear;
    this.yearGraphService.redrawGraphs();
  }
}
