<div class="container has-fixed-header has-2-fixed-headers">
    <h2 class="my-5">
        Exploring the Dynamics of Rural Attractiveness

    </h2>
    <p>The Index of Rural Attractiveness (IRA) used here is made up of 4 sub-indices: social, natural environment,
        human-made environment, and economic (read more about this here [link to page 5]). Having a look at these
        sub-indices helps to get a more differentiated picture of the rural attractiveness in the different regions: In
        which fields does a region perform well and in which fields does it lag behind? Drilling down deeper into the
        IRA also helps to better understand where changes in the overall IRA come from, in which fields changes occur
        that increase or decrease the overall value of the IRA.
    </p>
    <p>Thus, the visualizations below show the development of the 4 IRA sub-indices for the PoliRural pilot regions from
        2015 to 2040 under a business-as-usual scenario, which means everything continues as is. See here [link to page
        6] to find out more about the different visualization types:
    </p>

    <div class="card my-5">
        <ng-container *ngFor="let region of sdmDihService.regions; last as isLast">
            <region-graph [region]="region"></region-graph>
            <hr *ngIf="!isLast">
        </ng-container>
        <div class="card-body p-3">
            <h5 class="card-title">‘Dots’ visualization of sub-indices for the regions and their evolution over time]
            </h5>
        </div>
    </div>

    <div class="card my-5">
        <factor-year-graph></factor-year-graph>
        <div class="card-body p-3">
            <h5 class="card-title">‘Disc’ visualization of sub-indices for the regions and their evolution over time
            </h5>
        </div>
    </div>

    <div class="card my-5">
        <iframe class="iframe-100" id="iframe" frameborder="0" scrolling="no"
            src="https://polirural-rae.avinet.no/barchartrace?showSecondBarchartRace&showParamSelector&scenario=baseline&param1=rur_attr&param2=tourist_visitors"></iframe>
        <div class="card-body p-3">
            <h5 class="card-title">‘Data race’ visualization of sub-indices for the regions and their evolution over
                time</h5>
        </div>
    </div>

</div>