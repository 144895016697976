import {DiscsChartBaseService} from '../discs-chart-base.service';
import {Injectable} from '@angular/core';

import {SdmDihService} from '../../sdm-dih.service';

@Injectable({providedIn: 'root'})
export class ScenarioFactorYearGraphService extends DiscsChartBaseService {
  constructor(public sdmDihService: SdmDihService) {
    super(sdmDihService);
  }
}
