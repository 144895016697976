<main>
  <nav class="navbar navbar-dark fixed-top navbar-expand-lg bg-primary">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="/" href="#">
        <img src="assets/fig/logo_polirural_white.png" alt="" height="48"/>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" routerLink="/" routerLinkActive="active"
              ariaCurrentWhenActive="page">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/regional-foresight" routerLinkActive="active"
              ariaCurrentWhenActive="page">Regional foresight</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/text-mining" routerLinkActive="active" ariaCurrentWhenActive="page">Text
              mining</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/system-dynamics" routerLinkActive="active"
              ariaCurrentWhenActive="page">System dynamics</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/policy-options-explorer" routerLinkActive="active"
              ariaCurrentWhenActive="page">Policy options explorer</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/rural-attractiveness-explorer" routerLinkActive="active"
              ariaCurrentWhenActive="page">Rural attractiveness explorer</a>
          </li>
          <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/comparing-rural-attractiveness" routerLinkActive="active"
            ariaCurrentWhenActive="page">Comparing rural attractiveness</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/exploring-dynamics" routerLinkActive="active"
            ariaCurrentWhenActive="page">Exploring dynamics</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/exploring-impact" routerLinkActive="active"
            ariaCurrentWhenActive="page">Exploring impact</a>
        </li> -->
        </ul>
      </div>
    </div>
  </nav>
  <router-outlet></router-outlet>