<div class="container has-fixed-header has-2-fixed-headers">
        <h2 class="my-5">
                Comparing Rural Attractiveness across the Regions of Europe

        </h2>
        <p>Comparing the rural attractiveness of different regions in Europe can provide various insights:
                Evidently, it
                helps to get an impression on where a region stands compared to the rest of Europe.
                Additionally, by
                looking at regions that fare especially well in terms of rural attractiveness, it is possible to
                find
                out about best practices that may help other regions to improve their rural attractiveness, too.
                Also,
                finding regions that are similar in their rural attractiveness characteristics can help to
                establish
                cooperation between these similar regions so they can learn from each other and possibly work
                together
                on strategies to advance in terms of rural attractiveness.
        </p>
        <p>In PoliRural, we have developed several tools that support the comparison of different European
                regions
                regarding their rural attractiveness:
        </p>
        <p>Our Rural Attractiveness Map application shows the current values of the Index of Rural
                Attractiveness (IRA)
                [link to page 5] for all regions in Europe. The Map application allows you to change the
                settings of the
                IRA (Which aspects are to be included in the Index? What is the relative importance of the 6
                sub-indices
                to you?). Thus, the map can be adapted to your own preferences and needs. Additionally, the Map
                application can also show groups of similar regions (so-called clusters).
        </p>
        <p>The visualizations below allow a comparison of a different kind: They show the IRA only for the PoliRural
                pilot regions. In compensation, they do not only show current and past values of the Index but also
                provide a projection into the future. That means they show how rural attractiveness could develop if
                business-as-usual continues and no new policy measures are implemented.
        </p>
        <p>The so-called “dots visualization” shows on one sight the development of the IRA between 2015 and 2040.
                Thereby, each dot stands for a time period of 3 months (or 0.25 years). The values of the IRA are
                indicated by the colour of the dots: green hues mean a high IRA value, red hues indicate low IRA values.
        </p>
        <p>Please remember that the IRA values are not absolute but only show how the different assessed regions perform
                relative to each other. Additionally, the way the IRA is made up here is rather imperfect as we have
                included only a few sub-sub-indices and not all of the 6 IRA sub-indicators are represented here. This
                is owed to the experimental stage of the Rural Attractiveness Explorer. Therefore, if a region displays
                low IRA values here, this means that it is less attractive only compared to the other PoliRural pilot
                regions and only regarding the aspects that have been considered here (consult the graphic here [link to
                page 5] to see which aspects these are). If compared to other regions and considering different aspects
                of rural attractiveness, the same region could present very different IRA values.</p>
        <div class="card p-3 my-5">
                <all-in-one-graph></all-in-one-graph>
                <div class="card-body">
                        <p class="card-text"> “Dots” visualization for the project regions and its evolution over time
                        </p>
                </div>
        </div>

        <p>In the so-called “disc visualization”, each disc represents one region. On the time slider, you can either
                have the visualization run from 2015 to 2040 on its own or you can select specific time steps (one time
                step = 3 months/0.25 years). Also here, the color of the disc represents the IRA value at each time
                step. The direction of the arrow (up, straight, down) shows how the value of rural attractiveness has
                changed compared to the previous time step.</p>
        <div class="card p-3 my-5">
                <year-graph></year-graph>
                <div class="card-body">
                        <p class="card-text">‘Disc’ visualization for the project regions and its evolution over time
                        </p>
                </div>
        </div>

        <p>The “data race visualization” runs from 2015 to 2040 (with the possibility to stop and continue the race at
                any time), and the bars representing the different pilot regions change their length and order depending
                on their “performance” at each time step.</p>

        <div class="card p-3 my-5 col-8 mx-auto">
                <iframe id="iframe" class="iframe-100" frameborder="0" scrolling="no"
                        src="https://polirural-rae.avinet.no/barchartrace?domain=baseline&scenario=baseline"></iframe>
                <div class="card-body">
                        <p class="card-text">‘Data race’ visualization for the project regions and its evolution over
                                time</p>
                </div>
        </div>

</div>