import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {SdmDihService} from '../sdm-dih.service';

@Injectable({providedIn: 'root'})
export class DiscsChartBaseService {
  graphRefreshes: Subject<{region?: string; domain?: string}>;
  selectedYear = '';
  constructor(public sdmDihService: SdmDihService) {
    this.graphRefreshes = new Subject();
    this.sdmDihService.dataLoads.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
      this.selectedYear = this.sdmDihService.firstYear;
    });
  }

  getRegionProgress(regionData, {factor} = {factor: 'aggregated'}): -1 | 0 | 1 {
    //TODO: use i-2, i-1 comparison based on 2 previous steps
    const PARAM_TO_COMPARE = factor;
    const region = regionData['MODEL'];
    const year = regionData['TIME_STEP'];
    const factorValue =
      regionData[PARAM_TO_COMPARE]?.index ?? regionData[PARAM_TO_COMPARE];
    const thisYearValue = +Number.parseFloat(factorValue).toFixed(3);
    let pastYear = Number.parseFloat(year) - 0.25 + '';
    pastYear = pastYear.includes('.') ? pastYear : pastYear + '.0';
    const pastYearData = this.sdmDihService.sdmData[pastYear];
    if (!pastYearData) {
      // We are in the first time step, so there is nothing to compare
      return 0;
    }
    const pastYearDataRegion = pastYearData.find(
      (regionPastYear) => regionPastYear['MODEL'] === region
    );
    let pastYearValue =
      pastYearDataRegion?.[PARAM_TO_COMPARE]?.index ??
      pastYearDataRegion?.[PARAM_TO_COMPARE];
    pastYearValue = +Number.parseFloat(pastYearValue).toFixed(3);
    if (pastYearValue < thisYearValue) {
      return 1;
    }
    if (pastYearValue > thisYearValue) {
      return -1;
    }
    return 0;
  }

  redrawGraphs({region, domain}: {region?; domain?} = {}) {
    this.graphRefreshes.next({region, domain});
  }
}
