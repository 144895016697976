import { Component, OnInit } from '@angular/core';
import { SdmDihService } from 'src/app/sdm-dih.service';

@Component({
  selector: 'exploring-dynamics',
  templateUrl: './exploring-dynamics.component.html',
  styleUrls: ['./exploring-dynamics.component.scss']
})
export class ExploringDynamicsComponent implements OnInit {

  constructor(
    public sdmDihService: SdmDihService
  ) { }

  ngOnInit(): void {
  }

}
