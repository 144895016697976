<div class="container has-fixed-header has-2-fixed-headers">
    <h2 class="my-5"> The Rural Attractiveness Explorer
    </h2>
    <p>The Rural Attractiveness Explorer is designed to help assess the impacts of different policy choices on the rural
        attractiveness of the PoliRural pilot regions. It builds on the outputs of the Policy Options Explorer and
        transforms them into an Index of Rural Attractiveness (IRA) which is also visualized in various ways. Thus, it
        is
        supposed to allow users to get insights into how rural attractiveness in a region is affected by different
        policy
        strategies. </p>

    <p>Rural attractiveness is a rather complex concept - which renders its measurement anything but trivial. For
        instance, in PoliRural, we observed that concepts of rural attractiveness differ significantly from region to
        region, and are conditioned by developmental priorities such as the desire to attract investors, entrepreneurs,
        or
        tourists. Thus, what is understood as rural attractiveness may depend on where, when, and by whom attractiveness
        is
        determined Additionally, the attractiveness of a region is never absolute and can only be determined in
        comparison
        to other regions. This means that a region that is rather attractive compared to one set of regions can be
        rather
        unattractive compared to another set of regions.</p>

    <p>Nevertheless, there is some common understanding of and agreement on the general issues that matter for rural
        attractiveness. This minimum agreement provides the basis for the Index of Rural Attractiveness. The IRA is made
        up
        of 6 sub-indices, each of which corresponds to the areas generally considered relevant for rural attractiveness:
    </p>

    <ul>
        <li>social,</li>
        <li>natural environment,</li>
        <li>human-made environment,</li>
        <li>economic,</li>
        <li>institutional, </li>
        <li>cultural.</li>
    </ul>


    <p>Each of these 6 sub-indices in turn includes a number of sub-sub indices, e.g. the social sub-index can include
        sub-sub indices measuring the age composition or unemployment rate of the population in a region. </p>

    <p>The IRA reflects the characteristics of the concept of rural attractiveness: The values of the Index do not
        represent absolute estimates of the attractiveness of a region but reflect how attractive the assessed regions
        are
        in comparison to each other. To account for the context dependency of rural attractiveness, the concrete choice
        of
        sub-sub indices needs to be determined in each individual situation in which rural attractiveness is supposed to
        be
        evaluated. </p>

    <p>With the Rural Attractiveness Explorer, we want to enable users to explore the effects of different policy
        choices
        on the rural attractiveness of the PoliRural pilot regions. Thus, we transform the outputs of the Policy
        Explorer
        into a value of the IRA and visualise the results of that in different ways.</p>
    <p>Notably, the Policy Explorer was initially not designed to have its outputs fit with the concept and Index of
        Rural
        Attractiveness. Thus, we use a version of the IRA here that represents a lowest common denominator between the
        outputs of the Policy Explorer and the requirements of the Index of Rural Attractiveness. For this reason, the
        adapted IRA we are currently using in the Rural Attractiveness Explorer consists of only 4 instead of 6
        sub-indices.
        In future versions, the aim is to identify and include more areas of overlap between the outputs of the Policy
        Options Explorer and the IRA. For instance, future versions of this IRA will also consider total employment
        among
        the economic sub-sub indices, which is not included in the current version of the IRA.</p>

    <div class="card col-8 mx-auto my-5 shadow">
        <img class="card-img-top" src="assets/fig/fig-04-rurattridx.png" />
        <div class="card-body">
            <p class="card-text">Composition of the preliminary Index of Rural Attractiveness Index used in the
                Rural Attractiveness Explorer</p>
        </div>
    </div>

    <p>To find out more about rural attractiveness, consult these resources:
    </p>
    <ul>
        <li><a href="https://polirural.eu/wp-content/uploads/2021/11/D1.1..pdf">D1.1 PoliRural Vision For Attractive
                Rural
                Places & Professions</a></li>
        <li><a href="https://polirural.eu/wp-content/uploads/2021/11/D1.4..pdf">D1.4 Rural Attractiveness: Post-Needs
                Gathering Update</a></li>
        <li><a href="https://polirural.eu/wp-content/uploads/2021/11/D1.7..pdf">D1.7. Deliverable Rural Attractiveness:
                ThePost-Evaluation Update</a></li>
        <li><a href="https://polirural.eu/newsletter/5/">Newsletter No. 5 article “PoliRural consortium publishes 2
                Scientific Publications”</a></li>
    </ul>
    <p>Or skip directly to the different tools of the Rural Attractiveness Explorer:
    </p>
    <ul>
        <li><a [routerLink]="['/rural-attractiveness-explorer/comparing-rural-attractiveness']">Comparing Rural Attractiveness
                across the regions of Europe”</a> (tbd.)</li>
        <li><a routerLink="/rural-attractiveness-explorer/exploring-dynamics">Exploring the Dynamics of Rural
                Attractiveness (tbd.)</a></li>
        <li><a routerLink="/rural-attractiveness-explorer/exploring-impact">Exploring the Impact of Policy Options on
                Rural Attractiveness (tbd.)</a></li>
    </ul>
</div>