import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';

import {DiscsModule} from './discs-chart/discs.module';
import {DotsModule} from './dots-chart/dots.module';
import {MapModule} from './map-chart/map.module';
import { AppRoutingModule } from './app-routing.module';
import { RegionalForesightComponent } from './pages/regional-foresight/regional-foresight.component';
import { TextMiningComponent } from './pages/text-mining/text-mining.component';
import { SystemDynamicsModellingComponent } from './pages/system-dynamics-modelling/system-dynamics-modelling.component';
import { PolicyOptionsExplorerComponent } from './pages/policy-options-explorer/policy-options-explorer.component';
import { RuralAttractivenessExplorerComponent } from './pages/rural-attractiveness-explorer/rural-attractiveness-explorer.component';
import { ComparingRuralAttractivenessComponent } from './pages/comparing-rural-attractiveness/comparing-rural-attractiveness.component';
import { ExploringDynamicsComponent } from './pages/exploring-dynamics/exploring-dynamics.component';
import { ExploringImpactComponent } from './pages/exploring-impact/exploring-impact.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AboutRuralAttractivenessExplorerComponent } from './pages/about-rural-attractiveness-explorer/about-rural-attractiveness-explorer.component';


@NgModule({
  declarations: [
    AppComponent,
    RegionalForesightComponent,
    TextMiningComponent,
    SystemDynamicsModellingComponent,
    PolicyOptionsExplorerComponent,
    RuralAttractivenessExplorerComponent,
    ComparingRuralAttractivenessComponent,
    ExploringDynamicsComponent,
    ExploringImpactComponent,
    LandingPageComponent,
    AboutRuralAttractivenessExplorerComponent],
  imports: [
    BrowserModule,
    FormsModule,
    NgbNavModule,
    DiscsModule,
    DotsModule,
    MapModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
