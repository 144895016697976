<div class="container has-fixed-header">
    <h2 class="my-5"> System Dynamic Modelling applied to Regional Foresight</h2>
    <p> System Dynamic Modelling has a long history. It was pioneered in the 1960s by Jay Forrester, an MIT computer
        scientist and systems engineer. In 1961 he published a foundational work on System Dynamics in a book entitled
        “Industrial Dynamics.” This was followed by “Urban Dynamics” in 1969 and “World Dynamics” in 1972.</p>
    <p> His work demonstrated that our usual “models” for how the world works, fail to capture important aspects of
        world dynamics, such as complex non-linear behaviours, which if left unchecked, could lead to the collapse of
        entire earth systems and an end to ever increasing growth and prosperity. </p>
    <p> The Club of Rome commissioned the system dynamics team at MIT to carry out a “Project on the Predicament of
        Mankind.” This team included experts from the US, India, Germany, Norway, Turkey, and Iran, with expertise in
        domains such as population, pollution, agriculture, and natural resources. A summary of its findings was
        published in 1972 by the Club of Rome with the title “Limits to Growth.”</p>
    <p>
        This remains a very important work on the sustainability of human activity at planetary level. It has inspired a
        great many actors who now work on sustainability and the challenge of living on an increasingly crowded planet
        with limited natural resources. The
        Club of Rome exists to this day and currently advises the cabinet of Ursula von der Leyen, the President of the
        European Commission, on long-term strategy related to sustainability and the achievement of high-level goals
        related to the climate, energy, and biodiversity.</p>
    <p> The great advantage of SDM modelling, is its ability to capture real world complexity, and illustrate the
        interactions between seemingly unrelated phenomena, in terms of feedback and in tells of how dynamics plays out
        over time; how inevitable consequences of our choices today play out sometimes quickly, and sometimes much more
        slowly over time. </p>
    <p>
        One of the goals of the POLIRURAL project has been to experiment with the use of SDM as a support for vision
        building and policy co-design at local government level, as part of a regional or sub-regional Foresight
        initiative. This presents many challenges for those who develop and implement SDM models which are rich enough
        to address the issues that local policy designers might need to address, yet simple enough for use by
        non-experts, the wide variety of stakeholders taking part in a typical local Foresight initiative.</p>
    <p> POLIRURAL made significant progress on all of these issues. It first had to identify precise tasks of a
        Foresight exercise, where SDM might be used. It had to develop a core model of rural regions and adapt this core
        model to the specificities of each region of the project. It had to implement each of these regional models
        using local data sets and make it available for use via an online tool, called the Policy Options Explorer.</p>
    <p> The general approach is based on what we call “the three-layer model” or 3LM, where the user is presented with
    </p>
    <ul>
        <li> the first layer consisting of a small set of input parameters, where the user can simulate different policy
            options by choosing a different set of input parameters.</li>
        <li> A middle layer which contains all of the complex dynamics of the local economy, its society, and its
            environment, and</li>
        <li> A third layer, which contains a small number of KPIs, reflecting the performance of the region. </li>
    </ul>
    <p> This tool allows a group of stakeholders individually, or working together, to compare the impact of different
        policy choices such as investments in new experiences or infrastructure projects intended to facilitate access
        for tourism, on performance indicators such as the number of visitors, nights stayed and jobs created, and see
        how these play out over time. </p>
    <p> By way of illustration, the 3LM for exploring tourism related policy options might look like this.</p>
    <div class="card col-6 mx-auto my-5 shadow">
        <img class="card-img-top" src="assets/fig/fig-01-sdm.png" />
        <div class="card-body">
            <p class="card-text">Sample 3LM for exploring tourism</p>
        </div>
    </div>
    <p> The model itself can be improved. Just putting this diagram on a slide and projecting it on a wall as support
        for a groupwork session, can already provide support to useful discussions on the subject. This possibility
        is described in some detail in project deliverable D5.5 listed below. The project has developed two interactive
        systems allowing groups of stakeholders to dig deeper into the exploration of policy options.
    <p> One of these is called the POE or Policy Options Explorer. Its interface looks something like this.</p>
    <div class="card col-6 mx-auto my-5 shadow">
        <img class="card-img-top" src="assets/fig/fig-02-sdm.png" />
        <div class="card-body">
            <p class="card-text">Screenshot from the Polirural Policy Option Explorer</p>
        </div>
    </div>
    <p> The other tool is called the RAE or Rural Attractiveness Explorer. It links the SDM models embedded in the POE
        to a simplified version of an Index of Rural Attractiveness developed by the project. The main purpose of
        this work is to explore the use of different ways for representing complex data and different types of user
        interfaces, with a view to understanding how best to present complex issues related to rural development and
        rural
        attractiveness to a general group of users, such as stakeholders taking part in local Foresight initiatives.</p>
    <p> To find out more the reader is invited to consult the following project resources: </p>
    <ul>
        <li><a href="">Article in Newsletter No. 10 entitled “Progress so far in the application of SDM to Regional
                Foresight.”</a>
        </li>
        <li><a href="https://polirural.eu/resources/newsletters/">Article in Newsletter No. 12 entitled “A Second Set of Experiments for Exploring the Application of SDM to
            Regional Foresight.”</a></li>
        <li> Training on SDM applied to Foresight (link to be added when MOOC 3 is completed)</li>
        <li>Project deliverable <a href="https://polirural.eu/wp-content/uploads/2021/11/D5.1..pdf">D5.1 “POLIRURAL Model ed 1”</a></li>
        <li>Project deliverable <a href="https://polirural.eu/wp-content/uploads/2021/11/D5.2..pdf">D5.2 “Polirural Model ed 2”</a></li>
        <li>Project deliverable <a href="https://polirural.eu/wp-content/uploads/2021/11/D5.3..pdf">D5.3 “Polirural Model ed 3”</a></li>
        <li>Project deliverable <a href="https://polirural.eu/wp-content/uploads/2021/11/D3.3..pdf">D3.3 “System Dynamics Tool – Technical Specifications”</a></li>
        <li>Project deliverable <a href="https://polirural.eu/wp-content/uploads/2021/11/D3.5..pdf">D3.5 “System Dynamics Tool – Initial Prototype”</a></li>
        <li>Project deliverable <a href="https://polirural.eu/wp-content/uploads/2022/06/D5.4-PoliRural-Model-4_final.pdf">D5.4 “Polirural Model ed 4”</a></li>
        <li>Project deliverable <a href="https://polirural.eu/wp-content/uploads/2022/06/D5.5-A-Dynamic-Rural-Development-Model_final.pdf">D5.5 “A Dynamic Rural Development Model”</a> </li>
        </ul>
<p>Or continue to either:</p>
        <ul>
        <li><a routerLink="/policy-options-explorer">The Policy Options Explorer</a></li>
        <li><a routerLink="/rural-attractiveness-explorer">The Rural Attractiveness Explorer</a></li>
    </ul>

</div>