import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'text-mining',
  templateUrl: './text-mining.component.html',
  styleUrls: ['./text-mining.component.scss']
})
export class TextMiningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
