<div class="card">
  <h2>Comparison of different scenarios</h2>
  <div *ngIf="sdmDihService.yearsLoaded; else loading" class="card-body">
    <select [(ngModel)]="selectedRegion" (ngModelChange)="yearGraphService.redrawGraphs({region: selectedRegion})">
      <ng-container *ngFor="let region of sdmDihService.regions">
        <option [ngValue]="region">{{region}}</option>
      </ng-container>
    </select>
    <select [(ngModel)]="selectedDomain" (ngModelChange)="yearGraphService.redrawGraphs({domain: selectedDomain})">
      <ng-container *ngFor="let domain of sdmDihService.domains | filter:baselineFilter">
        <option [ngValue]="domain">{{domain}}</option>
      </ng-container>
    </select>
    <div class="year-range">
      <span><button (click)="prevYear()">&#9665;</button></span>
      <span><button (click)="animateGraphs()">&#9654;</button></span>
      &emsp;
      <span>{{sdmDihService.firstYear}}</span>
      <input type="range" class="form-range" [(ngModel)]="yearGraphService.selectedYear"
        (ngModelChange)="yearGraphService.redrawGraphs()" [min]="sdmDihService.firstYear" [max]="sdmDihService.lastYear"
        step="0.25" />
      <span>{{sdmDihService.lastYear}}</span>
      &emsp;
      <span><button (click)="nextYear()">&#9655;</button></span>
    </div>
    <!--select
    class="form-select form-select-lg mb-3"
    aria-label=".form-select-lg example"
    [(ngModel)]="selectedYear"
    (ngModelChange)="redrawGraphs()"
  >
    <option [ngValue]="year" *ngFor="let year of years">{{ year }}</option>
  </select-->

    <div class="d-flex">
      <div class="discs-caption">
        <h4>Aggregated RAI</h4>
        <p>
          of region {{selectedRegion}} in {{yearGraphService.selectedYear}}
        </p>
        <disc-legend [region]="selectedRegion" scenario="all-2"></disc-legend>
      </div>
      <disc [region]="selectedRegion" show="scenario" inSitu="true"></disc>
      <div *ngFor="let scenario of filteredScenarios">
        <scenario-factor-year-disc [region]="selectedRegion" [scenario]="scenario" show="scenario" inSitu="true">
        </scenario-factor-year-disc>
      </div>
    </div>

    <h3>Rural attractiveness factors</h3>
    <div class="d-flex">
      <div class="discs-caption">
        <h4>Baseline scenario</h4>
        <p>
          of region {{selectedRegion}} in {{yearGraphService.selectedYear}}
        </p>
        <disc-legend [region]="selectedRegion" scenario="baseline-2"></disc-legend>
      </div>
      <div *ngFor="let factor of sdmDihService.factors">
        <scenario-factor-year-disc [region]="selectedRegion" [factor]="factor" show="factor" inSitu="true">
        </scenario-factor-year-disc>
      </div>
    </div>
    <hr>
    <div *ngFor="let scenario of filteredScenarios; last as isLast">
      <div class="d-flex">
        <div class="discs-caption">
          <h4>{{scenario}} scenario</h4>
          <p>
            of region {{selectedRegion}} in {{yearGraphService.selectedYear}}
          </p>
          <disc-legend [region]="selectedRegion" [scenario]="scenario"></disc-legend>
        </div>
        <div *ngFor="let factor of sdmDihService.factors">
          <scenario-factor-year-disc [region]="selectedRegion" [factor]="factor" [scenario]="scenario" show="factor"
            inSitu="true"></scenario-factor-year-disc>
        </div>
      </div>
      <hr *ngIf="!isLast">
    </div>
  </div>
  <ng-template #loading> Loading data... </ng-template>
</div>
