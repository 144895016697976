<div class="card">
  <h2>Detailed overview of individual regions</h2>
  <p>An aggregated attractiveness index is composed of six factors: anthropic, cultural, economic, institutional,
    natural
    and social. Due to the incompleteness of data provided by different regions, we only present four of them below.</p>
  <div *ngIf="sdmDihService.yearsLoaded; else loading" class="card-body">
    <div class="year-range">
      <span><button (click)="prevYear()">&#9665;</button></span>
      <span><button (click)="animateGraphs()">&#9654;</button></span>
      &emsp;
      <span>{{sdmDihService.firstYear}}</span>
      <input type="range" class="form-range" [(ngModel)]="yearGraphService.selectedYear"
        (ngModelChange)="yearGraphService.redrawGraphs()" [min]="sdmDihService.firstYear" [max]="sdmDihService.lastYear"
        step="0.25" />
      <span>{{sdmDihService.lastYear}}</span>
      &emsp;
      <span><button (click)="nextYear()">&#9655;</button></span>
    </div>

    <ng-container *ngFor="let region of sdmDihService.regions; last as isLast">
      <!--select
    class="form-select form-select-lg mb-3"
    aria-label=".form-select-lg example"
    [(ngModel)]="selectedYear"
    (ngModelChange)="redrawGraphs()"
  >
    <option [ngValue]="year" *ngFor="let year of years">{{ year }}</option>
  </select-->
      <div class="d-flex">
        <div class="discs-caption">
          <h4>Rural attractiveness index</h4>
          <p>
            of region <strong>{{region}}</strong> in {{yearGraphService.selectedYear}}
          </p>
          <disc-legend [region]="region"></disc-legend>
        </div>
        <div *ngFor="let factor of sdmDihService.factors">
          <factor-year-disc [region]="region" [factor]="factor" [show]="'factor'"></factor-year-disc>
        </div>
      </div>
      <hr *ngIf="!isLast">
    </ng-container>
  </div>
  <ng-template #loading> Loading data... </ng-template>
</div>
