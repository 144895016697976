<div class="container has-fixed-header">

    <h2 class="my-5">An Overview of Regional Foresight</h2>
    <p>Foresight has a long history as a strategic management tool. It was employed initially in the development of
        national
        research agendas in countries like Japan. It was adopted by the European Commission with the establishment of a
        Cellule Prospective and the FAST program for Forecasting and Assessment in Science and Technology, under
        President
        Jacques Delors. Over the years, the practice of Foresight has evolved beyond national and EU inputs to science
        and
        technology policy. It is now applied to a much broader range of issues related to economic and social
        development,
        at sectoral, regional, and sub-regional level.&nbsp;</p>
    <p>Despite its emphasis on stakeholder engagement, the use of the term &lsquo;foresight&rsquo; is often confused
        with
        future studies. Foresight practitioners often make the mistake of focusing exclusively on engagement with
        stakeholders at grass roots level. This is unfortunate as it ignores the need for engagement with
        &lsquo;actors&rsquo;, those who get things done. That means the people in politics or public administration who
        hold
        the purse strings, have the power to allocate budgets or mobilize finance, enact legislation, or design and
        implement programs. Without effective engagement with such actors, reports stay on the shelf, the plans they
        contain, however well drafted, never get carried out and the &rsquo;visions&rsquo; of communities never become
        anything more than a dream.&nbsp;&nbsp;</p>
    <p>Aware of these issues, the POLIRURAL project provided a framework for the execution of its 12 regional Foresight
        initiatives, that include the following key elements.</p>
    <ul>
        <li>Bottom-up activities where the leadership team works with policy beneficiaries</li>
        <li>Top-down activities where the leadership team works with those who make policy happen</li>
        <li>Production of timely and adequate content to support the work at every stage of the process</li>
    </ul>
    <p>&nbsp;The overall process should produce a minimal set of documents including</p>
    <ul>
        <li>A vision, which also describes the challenges to be addressed to achieve that vision</li>
        <li>An action plan, with policy measures to address the challenges and their intervention logic</li>
        <li>A roadmap which explains measure sequencing, funding, and assignment of responsibility!&nbsp;</li>
    </ul>
    <p>Finally, we have asked all of the local leadership teams to measures intended to</p>
    <ul>
        <li>Secure endorsement of the vision and action plan by beneficiaries</li>
        <li>Secure adoption of the package by the actors who make policy happen</li>
        <li>Ensure the appointment of a monitoring committee to oversee implementation of the plan.&nbsp;</li>
    </ul>
    <p>For those who want to know more, they might like to consult the following set of project related resources.&nbsp;
    </p>
    <ul>
        <li>T<a href="https://youtu.be/m66wjhqLbg0?t=765" target="_blank" rel="noopener">raining session</a> by Patrick
            Crehan on Regional Foresight</li>
        <li><a href="https://polirural.eu/wp-content/uploads/2022/02/D1.8..pdf">D1.8 Future Outlooks Methodology</a></li>
        <li><a href="https://polirural.eu/resources/inventory-of-drivers-of-change/" target="_blank" rel="noopener">The
                STEEPV Inventory of Drivers of Change</a></li>
        <li><a href="https://polirural.eu/wp-content/uploads/2021/11/PoliRural-Foresight-Guide-to-Deep-Dives.pdf"
                target="_blank" rel="noopener">The Guide to Deep Dives &ndash; COVID Response</a></li>
        <li><a href="https://polirural.eu/wp-content/uploads/2022/02/Deep-Dive-CAP-Reform.pdf" target="_blank"
                rel="noopener">The Guide to Deep Dives &ndash; CAP Reform</a></li>
        <li><a href="https://polirural.eu/wp-content/uploads/2022/02/Deep-Dives-Green-Deal.pdf" target="_blank"
                rel="noopener">The Guide to Deep Dives &ndash; The Green Deal</a></li>
        <li><a href="https://polirural.eu/resources/newsletters/" target="_blank" rel="noopener">Newsletter No. 01
                article
                entitled &ldquo;Regional Foresight&rdquo;</a></li>
        <li><a href="https://polirural.eu/resources/newsletters/" target="_blank" rel="noopener">Newsletter No. 07
                article
                entitled &ldquo;The STEEPV Inventory of Drivers of Change&rdquo;</a></li>
        <li><a href="https://polirural.eu/resources/newsletters/" target="_blank" rel="noopener">Newsletter No. 09
                article
                entitled &ldquo;Tools for Regional Foresight - Guide to Deep Dives on the Regional Impact of COVID
                19&rdquo;</a></li>
        <li><a href="https://polirural.eu/resources/newsletters/" target="_blank" rel="noopener">Newsletter No. 11
                article
                entitled &ldquo;Tools for Regional Foresight - Guide to Deep Dives on CAP Reform&rdquo;</a></li>
        <li><a href="https://polirural.eu/" target="_blank" rel="noopener">The POLIRURAL project</a></li>
        <li><a href="https://polirural.eu/pilots/" target="_blank" rel="noopener">The Regional Foresight pilots</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1174" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Apulia, Italy</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1175" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Gevgelija-Strumica, Macedonia</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1176" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Galilee, Israel</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1170" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Central Bohemia, Czech Republic</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1170" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Slovakia</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1172" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Hame, Finland</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1173" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Central Greece</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1148" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Flanders, Belgium</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1152" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Monaghan, Ireland</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1153" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Segobriga, Spain</a></li>
        <li><a href="https://polirural.eu/wp-content/uploads/2021/11/04Vidzeme_Latvia.pdf" target="_blank"
                rel="noopener">The Regional Foresight pilot for Vidzeme, Latvia</a></li>
        <li><a href="https://polirural.eu/?smd_process_download=1&amp;download_id=1169" target="_blank"
                rel="noopener">The
                Regional Foresight pilot for Mazowiecke, Poland</a></li>
    </ul>
</div>