import { Component, OnInit } from '@angular/core';
import { SdmDihService } from '../../sdm-dih.service';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile, Vector as VectorLayer } from 'ol/layer';

import { HsConfig, HsEventBusService, HsToastService } from 'hslayers-ng';

@Component({
  selector: 'rural-attractiveness-explorer',
  templateUrl: './rural-attractiveness-explorer.component.html',
  styleUrls: ['./rural-attractiveness-explorer.component.scss']
})
export class RuralAttractivenessExplorerComponent implements OnInit {

  constructor(
    /* Inject here all modules from HSLayers-NG which you intend to use */
    public hsConfig: HsConfig /* public properties are visible in the template */,
    private hsEventBusService: HsEventBusService /* private properties are only visible from within this component class */,
    private hsToastService: HsToastService,
    public sdmDihService: SdmDihService
  ) {
    /* Define and update the HsConfig configuration object */
    this.hsConfig.update({
      datasources: [
        /* You need to set up Layman in order to use it. See https://github.com/LayerManager/layman */
        /*{
          title: 'Layman',
          url: 'http://localhost:8087',
          user: 'anonymous',
          type: 'layman',
          liferayProtocol: 'https',
        },*/
        /*{
          title: 'Micka',
          url: 'https://hub.sieusoil.eu/cat/csw',
          language: 'eng',
          type: 'micka',
        },*/
      ],
      /* Use hslayers-server if you need to proxify your requests to other services. See https://www.npmjs.com/package/hslayers-server */
      /* proxyPrefix: window.location.hostname.includes('localhost')
        ? `${window.location.protocol}//${window.location.hostname}:8085/`
        : '/proxy/',
      */
      useProxy: false,
      panelsEnabled: {
        tripPlanner: false,
        datasource_selector: false,
        composition_browser: false,
        saveMap: false,
        draw: false,
        permalink: false,
      },
      componentsEnabled: {
        basemapGallery: false,
      },
      assetsPath: 'assets',
      symbolizerIcons: [
        { name: 'beach', url: '/assets/icons/beach17.svg' },
        { name: 'bicycles', url: '/assets/icons/bicycles.svg' },
        { name: 'coffee-shop', url: '/assets/icons/coffee-shop1.svg' },
        { name: 'mountain', url: '/assets/icons/mountain42.svg' },
        { name: 'warning', url: '/assets/icons/warning.svg' },
      ],
      sidebarClosed: true,
      popUpDisplay: 'hover',
      default_layers: [
        /* One baselayer */
        new Tile({
          source: new OSM(),
          visible: true,
          properties: {
            title: 'OpenStreetMap',
            base: true,
            removable: false,
          },
        }),
        /* One thematic layer */
        //FIXME: missing loader?
        /*new VectorLayer({
          properties: {
            title: 'Polirural Pilot Regions',
            editor: {editable: false},
          },
          source: new VectorSource({
            format: new GeoJSON(),
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            url: require('../assets/data/pilot_regions.geojson').default,
          }),
          visible: true,
          style: new Style({
            stroke: new Stroke({
              color: '#1d941d',
              width: 1.5,
            }),
            fill: new Fill({
              color: 'rgba(29, 148, 29, 0.2)',
            }),
          }),
        }),*/
      ],
    });
    /* Now wait for the OpenLayers Map object to load */
    this.hsEventBusService.olMapLoads.subscribe(() => {
      /* ...and display a simple toast message in the bottom-left corner */
      // this.hsToastService.createToastPopupMessage(
      //   'READY!',
      //   'Your map is now ready to use.',
      //   {
      //     toastStyleClasses:
      //       'bg-success text-white' /* Use any Bootstrap class here, see https://getbootstrap.com/docs/4.0/utilities/colors/ */,
      //   }
      // );
    });
  }


  listener = (e) => {
    const i = window.document.getElementById("iframe");
    if (i) {
      (<HTMLIFrameElement>i).style.height = e.data.height + 'px';
    }
  }

  ngOnInit(): void {
    window.removeEventListener("message", this.listener, false);
    window.addEventListener("message", this.listener, false);
  }

  ngOnDestroy() {
    window.removeEventListener("message", this.listener, false);
  }

}
