<div class="container has-fixed-header has-2-fixed-headers">
    <h2 class="my-5">
        Exploring the Impact of different Policy Choices on the Rural Attractiveness of the PoliRural Pilot Regions

    </h2>
    <p> The ultimate purpose of the Rural Attractiveness Explorer is to support general groups of users in interpreting
        the future impacts of different policy strategies on the rural attractiveness of a region. Knowing about likely
        consequences of different policy interventions, stakeholders will be better equipped to make adequate decisions
        on actual rural development policies.
    </p>
    <p> Ideally, the Rural Attractiveness Explorer would allow users to draft various so-called “scenarios”, which are
        settings that simulate different policy measures, of their own choice and compare the development of rural
        attractiveness under these different scenarios. However, the Rural Attractiveness Explorer is still at an
        experimental stage and does not feature such an advanced functionality, yet.
    </p>
    <p> However, the team behind the Rural Attractiveness Explorer has developed a set of predefined policy scenarios to
        allow users to already explore and compare the impact of a couple of policy options on the rural attractiveness
        of the PoliRural pilot regions. Thus, the tools below allow the user to compare the impact of different
        scenarios in each of three domains, for a single region, both on the overall Index of Rural Attractiveness and
        on its sub-indices. The scenarios simulate the following policy options):
    </p>
    <ul>
        <li>In the domain of infrastructure development:</li>
        <ul>
            <li>business as usual: no policy interventions happen, everything continues as is.</li>
            <li>investment into broadband only: All investment is directed to improving broadband coverage.</li>
            <li>investment into roads only: All investment is dedicated to constructing new roads.</li>
            <li>investment into broadband & roads: 50% of investments are directed to improving broadband coverage and
                50% are dedicated to constructing new roads.</li>
        </ul>
        <li>In the domain Agriculture policy:</li>
        <ul>
            <li>business as usual: no policy interventions happen, everything continues as is.</li>
            <li>investment only into the agric. information & knowledge system (AKIS): …</li>
            <li>investment only into eco-schemes: …</li>
            <li>investment into AKIS & Eco-Schemes:...</li>
        </ul>
        <li>In the domain Support to Entrepreneurship:</li>
        <ul>
            <li> business as usual: no policy interventions happen, everything continues as is.
                High rate of support to entrepreneurs: …
                Medium rate of support to entrepreneurs: …
                Low rate of support to entrepreneurs: …</li>
        </ul>

    </ul>

    <p>These scenarios are designed for demonstration purposes as well as to allow users to understand how single policy
        measures play out. They are not meant to reflect any realistic policy strategies, which would include packages
        of
        different measures and more nuanced approaches.</p>


    <div class="card my-5">
        <scenario-factor-year-graph></scenario-factor-year-graph>
        <div class="card-body p-3">
            ‘Disc’ visualization of impact of different options on RA and its sub-indices for each domain
        </div>
    </div>

    <div class="card my-5 col-8 mx-auto">
        <iframe class="iframe-100" id="iframe" frameborder="0" scrolling="no"
            src="https://polirural-rae.avinet.no/barchartrace?showModelSelector&showParamSelector&showScenarioSelector&model=Hame"></iframe>
        <div class="card-body p-3">
            <h5 class="card-title">‘Data-race’ visualization of impact of different options on RA and its sub-indices
                for each domain</h5>
        </div>
    </div>


</div>