import {Component} from '@angular/core';

import {DiscComponent} from '../disc.component';
import {SdmDihService} from '../../sdm-dih.service';
import {YearGraphService} from './year-graph.service';

@Component({
  selector: 'year-disc',
  templateUrl: '../disc.component.html',
})
export class YearDiscComponent extends DiscComponent {
  constructor(
    public sdmDihService: SdmDihService,
    public graphService: YearGraphService
  ) {
    super(sdmDihService, graphService);
  }
}
