import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

import {SdmDihService} from '../../sdm-dih.service';

@Component({
  selector: 'region-graph',
  templateUrl: './region-graph.component.html',
  styleUrls: ['./region-graph.component.scss'],
})
export class RegionGraphComponent implements OnChanges {
  @Input() region: string;
  dataLoaded = false;
  data = [];
  plotlyLayout = {
    height: 180,
    cliponaxis: false,
    constraintext: 'none',
    yaxis: {
      automargin: true,
    },
    xaxis: {
      automargin: true,
    },
    margin: {
      l: 5,
      r: 5,
      b: 10,
      t: 10,
      pad: 4,
    },
    //width: '100%',
  };
  plotlyConfig = {
    staticPlot: true,
  };

  constructor(public sdmDihService: SdmDihService) {
    this.sdmDihService.dataLoads.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
      this.processData();
      this.dataLoaded = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processData();
    this.dataLoaded = true;
  }

  processData() {
    const PARAM_TO_COMPARE = 'aggregated';
    const years = [];
    const factors = [];
    const colors = [];
    for (const yearData of Object.values<any[]>(this.sdmDihService.sdmData)) {
      for (const regionData of yearData) {
        //console.log(regionData, this.region);
        if (regionData['MODEL'] !== this.region) {
          continue;
        }
        for (const [factor, value] of Object.entries(regionData)) {
          if (
            ['TIME_STEP', 'MODEL', 'SCENARIO', 'DOMAIN', 'aggregated'].includes(
              factor
            )
          ) {
            continue;
          }
          years.push(regionData['TIME_STEP']);
          const factorBeautifulName = factor.split('/').pop();
          factors.push(factorBeautifulName);
          colors.push(regionData[factor]?.index);
        }
      }
    }
    const trace1 = {
      x: years,
      y: factors,
      mode: 'markers',
      marker: {
        size: 10,
        color: colors,
        colorscale: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map(
          (perc) => {
            return [perc, this.sdmDihService.perc2color(perc)];
          }
        ),
      },
      type: 'scatter',
    };
    this.data = [trace1];
  }
}
