<div class="container has-fixed-header">

    <h2 class="my-5">
        Exploring New Tools to Support Regional Foresight Initiatives
    </h2>

    <div class="row mt-4 p-4">
        <p>
            From 2020 to 2022, twelve Regional Foresight exercises in the EU and neighbouring countries, provided a
            living
            laboratory for exploring the use of novel tools and techniques in highly participative approaches to
            local
            policy
            co-design, led by teams at regional and sub-regional level. To this end POLIRURAL teams designed and
            developed novel IT
            systems intended to support and enrich stakeholder engagement in those 12 regional Foresight exercises.
        </p>
        <a class="btn" [routerLink]="['rural-attractiveness-explorer', 'comparing-rural-attractiveness']" >Skip directly to the Rural Attractiveness Explorer</a>
    </div>
    <div class="row mt-4">
        <div class="col-8 p-5 bg-light">
            <p>
                One of those systems employed a wide range of Text Mining and Machine learning techniques to support the
                work of readers
                and researchers tasked with discovering relevant information in large libraries of documents, with a
                view to
                reducing
                the burden of work required of local leadership teams to provide stakeholders with short, relevant, and
                timely texts on
                complex issues that affect their region, and which may require a policy response. This tool is called
                the
                SEMANTIC
                EXPLORER (SE).
            </p>
        </div>
        <div class="col-4 p-3">
            <div class="card shadow">
                <img class="card-image-top" src="assets/fig/ill-text-mining.jpeg" />
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-4">
            <div class="card shadow">
                <img class="card-image-top" src="assets/fig/ill-sdm.png" />
            </div>
        </div>
        <div class="col-8 p-5 bg-light">
            <p>
                Our biggest effort was devoted to the use of System Dynamic Modelling or SDM, in a tool allowing normal
                citizens to
                explore the impact of different policy options on regional KPIs or indicators of performance. This tool
                is
                called the
                POLICY OPTIONS EXPLORER (POE).
            </p>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-8 p-5 bg-light">
            <p>
                Our third tool is the RURAL ATTRACTIVENESS EXPLORER (RAE). It connects the POE to a composite Index of
                Rural
                Attractiveness (IRA), allowing users to see how different policy choices will play out over time in
                terms of
                their
                impact on an
            </p>
        </div>
        <div class="col-4">
            <div class="card shadow">
                <img class="card-image-top" src="assets/fig/ill-rae.png" />
            </div>
        </div>
    </div>
    <div class="row mt-4 p-4">
        <p>
            An important aspect of all of this work was to better understand how one can help non-experts, as opposed to
            professional economists, statisticians, and modellers, to better understand how the world is changing, how
            the
            challenge
            of growth and development is evolving, and the range of available real-world policy options, with the
            intention
            of
            being
            able to take part in a meaningful and impactful local policy process.
        </p>
    </div>
    <div class="row mt-4 p-4">
        <p>
            To find out more, the reader is invited to follow the links to our pages on
        </p>
        <nav>

            <ul>
                <li>
                    <a routerLink="/regional-foresight" routerLinkActive="active" ariaCurrentWhenActive="page">
                        Regional foresight
                    </a>
                </li>
                <li>
                    <a routerLink="/text-mining" routerLinkActive="active" ariaCurrentWhenActive="page">
                        Text Mining applied to Regional Foresight
                    </a>
                </li>
                <li>
                    <a routerLink="/system-dynamics" routerLinkActive="active" ariaCurrentWhenActive="page">
                        System Dynamic Modelling
                    </a>
                </li>
            </ul>
            <p>
                or skip directly to
            </p>
            <ul>
                <li><a href="https://semex.io/">The SE tool</a></li>
                <li>
                    <a routerLink="/policy-options-explorer" routerLinkActive="active" ariaCurrentWhenActive="page">
                        The Policy Options Explorer
                    </a>
                </li>
                <li>
                    <a routerLink="/rural-attractiveness-explorer">
                        The Rural Attractiveness Explorer
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>