import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'exploring-impact',
  templateUrl: './exploring-impact.component.html',
  styleUrls: ['./exploring-impact.component.scss']
})
export class ExploringImpactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
