import {Component} from '@angular/core';

import {DiscComponent} from '../disc.component';
import {ScenarioFactorYearGraphService} from './scenario-factor-year-graph.service';
import {SdmDihService} from '../../sdm-dih.service';

@Component({
  selector: 'scenario-factor-year-disc',
  templateUrl: '../disc.component.html',
})
export class ScenarioFactorYearDiscComponent extends DiscComponent {
  constructor(
    public sdmDihService: SdmDihService,
    public graphService: ScenarioFactorYearGraphService
  ) {
    super(sdmDihService, graphService);
  }
}
