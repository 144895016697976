import {Component, OnInit} from '@angular/core';

import {SdmDihService} from '../../sdm-dih.service';

@Component({
  selector: 'all-in-one-graph',
  templateUrl: './all-in-one-graph.component.html',
  styleUrls: ['./all-in-one-graph.component.scss'],
})
export class AllInOneGraphComponent implements OnInit {
  dataLoaded = false;
  data = [];
  plotlyLayout = {
    height: 270,
    cliponaxis: false,
    constraintext: 'none',
    yaxis: {
      automargin: true,
    },
    xaxis: {
      automargin: true,
    },
    margin: {
      l: 5,
      r: 5,
      b: 10,
      t: 10,
      pad: 4,
    },
    //hovermode: '',
    //width: '100%',
  };
  plotlyConfig = {
    //staticPlot: true,
  };

  constructor(public sdmDihService: SdmDihService) {}

  ngOnInit(): void {
    this.sdmDihService.dataLoads.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
      this.processData();
      this.dataLoaded = true;
    });
  }

  processData() {
    //TODO: parametrize the PARAM_TO_COMPARE
    const PARAM_TO_COMPARE = 'aggregated';
    const years = [];
    const regions = [];
    const colors = [];
    for (const yearData of Object.values<any[]>(this.sdmDihService.sdmData)) {
      for (const regionData of yearData) {
        years.push(regionData['TIME_STEP']);
        regions.push(regionData['MODEL']);
        colors.push(regionData[PARAM_TO_COMPARE]);
      }
    }
    const trace1 = {
      x: years,
      y: regions,
      mode: 'markers',
      marker: {
        size: 10,
        color: colors,
        colorscale: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map(
          (perc) => {
            return [perc, this.sdmDihService.perc2color(perc)];
          }
        ),
      },
      type: 'scatter',
    };
    this.data = [trace1];
  }
}
