import {Component} from '@angular/core';

// import {Fill, Stroke, Style} from 'ol/style';
// import {GeoJSON} from 'ol/format';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile, Vector as VectorLayer} from 'ol/layer';

import {HsConfig, HsEventBusService, HsToastService} from 'hslayers-ng';
import {SdmDihService} from './sdm-dih.service';

@Component({
  selector: 'application-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(public sdmDihService: SdmDihService) {}
}
