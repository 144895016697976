<div class="container has-fixed-header">
    <h2 class="my-5">The Policy Options Explorer</h2>

    <div class="row">
        <div class="col-6">
            <p>The purpose of the Policy Options Explorer is to allow users to explore how different policy choices and
                strategies play out in their region in the future. The Policy Options Explorer builds on <a
                    routerLink="/system-dynamics-modelling">PoliRural SDM ed.3</a>. However, this general model was
                adapted to
                represent the dynamics present in the PoliRural
                pilot regions, resulting in 10 different models through which impacts of different policies in the
                PoliRural
                pilot regions can be simulated.
            </p>
        </div>
        <div class="col-6 px-5 pb-5">
            <div class="card">
                <img src="assets/fig/fig-02-sdm.png" class="card-img-top">
                <div class="card-body">
                    <h5 class="card-title">Policy Options Explorer</h5>
                    <p class="card-text">The Policy Options Explorer is a stand-alone tool. If you wish to test the
                        tool, you may login with the credentials demo/demo</p>
                </div>
                <div class="card-body">
                    <a href="https://polirural-sdm.avinet.no" class="card-link" target="_blank">Open the Policy Options
                        Explorer (opens a new browser windows)</a>
                </div>
            </div>
        </div>
    </div>
    <p>The model adaptation was realised through two series of meetings with the main responsible contacts of the
        different pilots. In the first round of meetings, the model was fully explained so that pilots could understand
        the dynamics explained and the assumptions made. Additionally, the meetings were designed to best fit local
        dynamics into the template model. In the second round of meetings, a period was open for pilots to gather data.
        This period was open to clarification to find the best proxies, and discussion about qualitative data
        collection.</p>
    <p>Finally, the calibration was intended with the available data. For more details, see:
    </p>
    <ul>
        <li>deliverable 5.4 (tbd.)</li>
        <li>links to newsletters (tbd.)</li>
    </ul>
    <p>Or continue to the <a routerLink="/rural-attractiveness-explorer">Rural Attractiveness Explorer</a>.</p>
    <div class="card col-6 mx-auto my-5 shadow">
        <img class="card-img-top" src="assets/fig/fig-03-popt.png" />
        <div class="card-body">
            <p class="card-text">Overview of available models in the Policy Options Explorer</p>
        </div>
    </div>
</div>