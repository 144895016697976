<div class="container has-fixed-header">
    <h2 class="my-5">
        Text Mining applied to Regional Foresight
    </h2>
    <p>The full and meaningful participation of stakeholders in policy co-design processes, cannot happen unless efforts
        are made to make sure that the stakeholders are adequately informed about the complex challenges that the region
        faces, or of the currently feasible policy options by which they may be addressed.
    </p>
    <p>The successful execution of a Foresight initiative therefore requires someone or preferably a small team of
        people, to carry out lots of reading and lots of editing of short explanatory texts, intended to support the
        specific stages and tasks that make up the overall Foresight process.
    </p>
    <p>Many different techniques are applied in the execution of such work. ‘Horizon scanning’ is one of those basic
        tasks. This is an open and exploratory activity intended to understand what is happening in the world, in
        society, in business, in technology, in the world of research and innovation, and then analyse what is happening
        to understand what trends may have an impact on the region, and how these might play out over time.
    </p>
    <p>One way for organizing such work is to apply the principle, often attributed to the author William Gibson, that
        “the future is now,” all around us, but unevenly distributed. In this way, part of the challenge of the reader
        or researcher providing support to the Foresight process, is to read material from a wide variety of sources,
        and ‘make sense’ of that material in the context of the purpose of the Foresight exercise, given the specificity
        of the region and other policy initiatives that may already be in play. This work not only involves reading and
        sense-making, but discovering new topics or issues that may lie ‘below the radar’ of local officials and local
        citizens, but which may emerge to grow in importance, and have a significant impact on the region at some point
        in the future. It also requires the writing of summary texts that explain the issue and its possible
        significance for the reader in a language that is accessible to a general audience, which may or may not have
        access to people who are experts in that domain.
    </p>
    <p>The POLIRURAL project was founded on the belief that Text mining or TM, could support the work of the reader or
        researcher in the execution of high-level tasks such as horizon scanning, with the ultimate aim of improving the
        completeness of the reader’s topic discovery, the quality of their summary texts and the overall efficiency and
        productivity of their work.
    </p>
    <p>For these reasons, the POLIRURAL project developed a set of TM tools, intended to help the local Foresight teams
        in their work of ‘topic discovery’ and ‘topic summarization.’ This work is finished now, and the lessons have
        been learned.
    </p>
    <p>Anyone interested in knowing more is invited to inspect the related project deliverables
    </p>
    <ul>
        <li><a href="https://www.youtube.com/watch?v=Y0YlBi6n2yM">Training Session on the use of Text Mining by KAJO</a>
        </li>
        <li><a href="https://polirural.eu/resources/semantic-explorer-users-guide/">The Semantic Explorer (SE) User Guide</a>
        </li>
        <li><a href="https://semex.io/">The SE Tool</a></li>
    </ul>
    <p>Related project deliverables such as</p>
    <ul>

        <li>
            <a href="https://polirural.eu/wp-content/uploads/2021/11/D2.1..pdf">D2.1 Text Mining Technical Specifications</a>
        </li>
        <li>
            <a href="https://polirural.eu/wp-content/uploads/2021/11/D2.2..pdf">D2.2 Prototype Text Mining Solution</a>
        </li>
        <li>
            <a href="https://polirural.eu/wp-content/uploads/2021/11/D2.3..pdf">D2.3 Final text Mining Solution</a>
        </li>
    </ul>
    <p>
        Patrick Crehan of CKA presented a series of recommendations for further work based on our experience of using
        Text Mining in Foresight in the POLIRURAL project, at The XXXIII ISPIM Innovation Conference "Innovating in a
        Digital World", held in Copenhagen, Denmark on 05 June to 08 June 2022. Event Proceedings published by LUT
        Scientific and Expertise Publications with ISBN 978-952-335-694-8.
    </p>
    <ul>
        <li>
            Text Mining for Effective Stakeholder Engagement in Foresight, (tbd.)
        </li>
        <li>
            ISPIM conference presentation (tbd.)
        </li>
    </ul>

</div>