<div class="card">
  <h2>Rural attractiveness index of regions in {{yearGraphService.selectedYear}}</h2>
  <div *ngIf="sdmDihService.yearsLoaded; else loading" class="card-body">
    <div class="year-range">
      <span><button (click)="prevYear()">&#9665;</button></span>
      <span><button (click)="animateGraphs()">&#9654;</button></span>
      &emsp;
      <span>{{sdmDihService.firstYear}}</span>
      <input type="range" class="form-range" [(ngModel)]="yearGraphService.selectedYear"
        (ngModelChange)="yearGraphService.redrawGraphs()" [min]="sdmDihService.firstYear" [max]="sdmDihService.lastYear"
        step="0.25" />
      <span>{{sdmDihService.lastYear}}</span>
      &emsp;
      <span><button (click)="nextYear()">&#9655;</button></span>
    </div>
    <!--select
    class="form-select form-select-lg mb-3"
    aria-label=".form-select-lg example"
    [(ngModel)]="selectedYear"
    (ngModelChange)="redrawGraphs()"
  >
    <option [ngValue]="year" *ngFor="let year of years">{{ year }}</option>
  </select-->
    <div class="d-flex">
      <div *ngFor="let region of sdmDihService.regions">
        <year-disc [region]="region"></year-disc>
      </div>
    </div>
    <disc-legend></disc-legend>
  </div>
  <ng-template #loading> Loading data... </ng-template>
</div>
