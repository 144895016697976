import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'about-rural-attractiveness-explorer',
  templateUrl: './about-rural-attractiveness-explorer.component.html',
  styleUrls: ['./about-rural-attractiveness-explorer.component.scss']
})
export class AboutRuralAttractivenessExplorerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
