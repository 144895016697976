import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {HslayersModule} from 'hslayers-ng';

import {MapComponent} from './map.component';

@NgModule({
  imports: [CommonModule, FormsModule, HslayersModule],
  exports: [MapComponent],
  declarations: [MapComponent],
  providers: [],
})
export class MapModule {}
