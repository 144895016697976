import {Component} from '@angular/core';

import {SdmDihService} from '../../sdm-dih.service';
import {YearGraphService} from './year-graph.service';

@Component({
  selector: 'year-graph',
  templateUrl: './year-graph.component.html',
  styleUrls: ['./year-graph.component.scss'],
})
export class YearGraphComponent {
  constructor(
    public sdmDihService: SdmDihService,
    public yearGraphService: YearGraphService
  ) {}

  animateGraphs() {
    const MILLISECONDS_TO_ANIMATE = 100 as const;
    let i = 1;
    for (const year of this.sdmDihService.years) {
      setTimeout(() => {
        this.yearGraphService.selectedYear = year;
        this.yearGraphService.redrawGraphs();
      }, MILLISECONDS_TO_ANIMATE * i);
      i++;
    }
  }

  nextYear() {
    const selectedYearIndex = this.sdmDihService.years.findIndex(
      (val) => val == this.yearGraphService.selectedYear
    );
    this.yearGraphService.selectedYear =
      this.sdmDihService.years[selectedYearIndex + 1] ??
      this.yearGraphService.selectedYear;
    this.yearGraphService.redrawGraphs();
  }

  prevYear() {
    const selectedYearIndex = this.sdmDihService.years.findIndex(
      (val) => val == this.yearGraphService.selectedYear
    );
    this.yearGraphService.selectedYear =
      this.sdmDihService.years[selectedYearIndex - 1] ??
      this.yearGraphService.selectedYear;
    this.yearGraphService.redrawGraphs();
  }
}
