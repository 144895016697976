import {Component} from '@angular/core';

import {DiscComponent} from '../disc.component';
import {FactorYearGraphService} from './factor-year-graph.service';
import {SdmDihService} from '../../sdm-dih.service';

@Component({
  selector: 'factor-year-disc',
  templateUrl: '../disc.component.html',
})
export class FactorYearDiscComponent extends DiscComponent {
  constructor(
    public sdmDihService: SdmDihService,
    public graphService: FactorYearGraphService
  ) {
    super(sdmDihService, graphService);
  }
}
