import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {DiscComponent} from './disc.component';
import {DiscLegendComponent} from './disc-legend.component';
import {FactorYearDiscComponent} from './factor-year-graph/factor-year-disc.component';
import {FactorYearGraphComponent} from './factor-year-graph/factor-year-graph.component';
import {FilterPipe} from './filter.pipe';
import {QuarterPipe} from './quarter.pipe';
import {ScenarioFactorYearDiscComponent} from './scenario-factor-year-graph/scenario-factor-year-disc.component';
import {ScenarioFactorYearGraphComponent} from './scenario-factor-year-graph/scenario-factor-year-graph.component';
import {YearDiscComponent} from './year-graph/year-disc.component';
import {YearGraphComponent} from './year-graph/year-graph.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [
    FactorYearGraphComponent,
    YearGraphComponent,
    ScenarioFactorYearGraphComponent,
    FilterPipe,
  ],
  declarations: [
    DiscComponent,
    DiscLegendComponent,
    FactorYearDiscComponent,
    FactorYearGraphComponent,
    YearDiscComponent,
    YearGraphComponent,
    ScenarioFactorYearDiscComponent,
    ScenarioFactorYearGraphComponent,
    FilterPipe,
    QuarterPipe,
  ],
  providers: [],
})
export class DiscsModule {}
