import Plotly from 'plotly.js-dist-min';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {PlotlyModule} from 'angular-plotly.js';

import {AllInOneGraphComponent} from './all-in-one-graph/all-in-one-graph.component';
import {RegionGraphComponent} from './region-graph/region-graph.component';

PlotlyModule.plotlyjs = Plotly;

@NgModule({
  imports: [CommonModule, FormsModule, PlotlyModule],
  exports: [RegionGraphComponent, AllInOneGraphComponent],
  declarations: [RegionGraphComponent, AllInOneGraphComponent],
  providers: [],
})
export class DotsModule {}
