import {AfterViewInit, Component, Input, OnInit} from '@angular/core';

import {select} from 'd3';

import {SdmDihService} from '../sdm-dih.service';

@Component({
  selector: 'disc-legend',
  templateUrl: './disc-legend.component.html',
})
export class DiscLegendComponent implements OnInit, AfterViewInit {
  @Input() region = 'all';
  @Input() scenario = 'baseline';
  divId = 'graph-legend-place';

  constructor(public sdmDihService: SdmDihService) {}

  ngOnInit(): void {
    const regionSafeName = this.region.replaceAll(' ', '_');
    const scenarioSafeName = this.scenario.replaceAll(' ', '_');
    this.divId = `graph-place-${regionSafeName}-${scenarioSafeName}`;
  }

  ngAfterViewInit(): void {
    this.sdmDihService.dataLoads.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
      this.drawLegend();
    });
  }

  drawLegend(): void {
    //select(`#graph-legend-place`).remove();
    const width = 200;
    const height = 20;
    const g = select(`#${this.divId}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .style('overflow', 'visible')
      .style('display', 'block')
      .append('g');
    for (let i = 0; i < 10; i++) {
      g.append('rect')
        .attr('x', i * 20)
        .attr('y', 0)
        .attr('width', width / 10)
        .attr('height', height / 2)
        .attr('fill', this.sdmDihService.perc2color(i / 10));
    }
    g.append('text')
      .attr('x', 0 - 10)
      .attr('y', 20)
      .attr('dy', '.5em')
      .text('0 %');
    g.append('text')
      .attr('x', 200 - 10)
      .attr('y', 20)
      .attr('dy', '.5em')
      .text('100 %');
  }
}
