import {Component, OnInit} from '@angular/core';

import {FactorYearGraphService} from './factor-year-graph.service';
import {SdmDihService} from '../../sdm-dih.service';

@Component({
  selector: 'factor-year-graph',
  templateUrl: './factor-year-graph.component.html',
  styleUrls: ['./factor-year-graph.component.scss'],
})
export class FactorYearGraphComponent implements OnInit {
  constructor(
    public sdmDihService: SdmDihService,
    public yearGraphService: FactorYearGraphService
  ) {}

  ngOnInit() {
    this.sdmDihService.dataLoads.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
      //unused
    });
  }

  animateGraphs() {
    const MILLISECONDS_TO_ANIMATE = 100 as const;
    let i = 1;
    for (const year of this.sdmDihService.years) {
      setTimeout(() => {
        this.yearGraphService.selectedYear = year;
        this.yearGraphService.redrawGraphs();
      }, MILLISECONDS_TO_ANIMATE * i);
      i++;
    }
  }

  nextYear() {
    const selectedYearIndex = this.sdmDihService.years.findIndex(
      (val) => val == this.yearGraphService.selectedYear
    );
    this.yearGraphService.selectedYear =
      this.sdmDihService.years[selectedYearIndex + 1] ??
      this.yearGraphService.selectedYear;
    this.yearGraphService.redrawGraphs();
  }

  prevYear() {
    const selectedYearIndex = this.sdmDihService.years.findIndex(
      (val) => val == this.yearGraphService.selectedYear
    );
    this.yearGraphService.selectedYear =
      this.sdmDihService.years[selectedYearIndex - 1] ??
      this.yearGraphService.selectedYear;
    this.yearGraphService.redrawGraphs();
  }
}
