import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'regional-foresight',
  templateUrl: './regional-foresight.component.html',
  styleUrls: ['./regional-foresight.component.scss']
})
export class RegionalForesightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
